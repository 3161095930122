import * as React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import LazyLoad from "react-lazyload";

const HomeHero = ({
  title,
  subtitle,
  logo,
  intro,
  media,
  badge,
  heroMedia,
}) => {
  const image = media?.videoPosterchildImageSharp || media;
  const mediaClass = heroMedia || media ? " hero-with-media" : "";
  const headerClass = media || heroMedia ? "hero-title" : "heading";

  return (
    <header className={`hero${mediaClass}`}>
      <div className="container">
        <div>
          <div className={headerClass}>
            {logo ? (
              <GatsbyImage image={logo.gatsbyImageData} alt={logo.title} />
            ) : (
              title
            )}
          </div>
          {subtitle && <h1 className="main-title">{subtitle}</h1>}
          {intro && (
            <p
              className="introduction"
              dangerouslySetInnerHTML={{ __html: intro }}
            />
          )}
        </div>
      </div>
      {media && (
        <figure>
          {image.gatsbyImageData && (
            <GatsbyImage
              image={image.gatsbyImageData}
              alt={image.title}
              loading="eager"
              fetchpriority="high"
              aria-hidden={media.video ? true : undefined}
            />
          )}
          {media.video && (
            <LazyLoad>
              <video
                width={1920}
                height={1080}
                autoPlay
                muted
                loop
                playsInline
                aria-hidden
              >
                <source src={media.video.publicURL} type="video/mp4" />
              </video>
            </LazyLoad>
          )}
        </figure>
      )}
      {heroMedia && (
        <figure>
          {heroMedia.gatsbyImageData && (
            <GatsbyImage
              image={heroMedia.gatsbyImageData}
              alt={heroMedia.title}
              loading="eager"
              fetchpriority="high"
              aria-hidden={
                heroMedia.file?.contentType === "video/mp4" ? true : undefined
              }
            />
          )}
          {heroMedia.file?.contentType === "video/mp4" && (
            <LazyLoad>
              <video
                width={1920}
                height={1080}
                autoPlay
                muted
                loop
                playsInline
                aria-hidden
              >
                <source src={heroMedia.file?.url} type="video/mp4" />
              </video>
            </LazyLoad>
          )}
        </figure>
      )}
      {badge && (
        <img
          src={badge.file.url}
          alt={badge.title}
          width={badge.file.details.image.width}
          height={badge.file.details.image.height}
          decoding="async"
        />
      )}
    </header>
  );
};

export default HomeHero;
